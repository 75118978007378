.intro {
  height: 90vh;
  background: linear-gradient(329deg, #400863 0%, #33065c 60%);
  color: white;
  text-shadow: 0 0 2px #33065c;
  > div.intro-container {
    // position: relative;
    max-width: 1080px;
    margin: auto;
    > div {
      // min-width: 350px;
      position: absolute;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      &.content {
        z-index: 1;
        width: 100%;
        left: 5%;
        @media screen and (min-width: 900px) {
          width: 50%;
        }
      }
      &.images {
        right: 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
