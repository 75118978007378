.register{
 padding: 10px;
 & > div{
    margin:5px
 }
 .btn {
    text-align: center;
    button {
      padding: 5px 10px;
      background-color: #6c29e9;
      color: white;
      border: none;
      border-radius: 0.5em;

      margin: 2px;
    }
  }
}