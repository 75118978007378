nav{
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  &.home {
    color: white;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-color: #33065c;
    z-index: 2;
    text-shadow: 0 0 2px #33065c;
    transition: all 1s;
    &.scrolled {
      background-color: #33065ccc;
    }
    img {
      height: 50px;
      transition: all 1s;
      &.scrolled{
        height: 80px;
      }
    }
    h1 {
      text-shadow: 0 0 2px #33065c;
      font-size: 1em;
      margin-block-start: 0;
      margin-block-end: 0;
      transition: all 1s;
      &.scrolled {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
      }
    }
    .btn {
      text-align: center;
      button {
        padding: 5px 10px;
        background-color: #6c29e9;
        color: white;
        border: none;
        border-radius: 0.5em;
  
        margin: 2px;
      }
    }
  }
}
